import { classNames } from '@helpers/scls'
import styles from './Markdown.module.scss'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Text } from '@mantine/core'
import { Flex, FlexProps } from '@components/flex'
import { MarkdownNormalizer } from './normalizers'
import { memo, useEffect, useState } from 'react'
import useBooleanHelper from '@helpers/hooks/useBooleanHelper'

const DEFAULT_NORMALIZER: MarkdownNormalizer = (data) => data

export enum MarkdownVariant {
  /**
   * Use this for custom helper text. This is often user-defined guides for custom inputs or sections.
   */
  GUIDE = 'guide',

  /**
   * Use this for data coming from an AI response that contains generated output for things like proposals.
   */
  AI_RESPONSE = 'ai-response',

  /**
   * Use this for rendering chat messages.
   */
  CHAT = 'chat',

  /**
   * Use this for introducing features in the app.
   */
  FEATURE_DESCRIPTION = 'feature-description',
}

export interface MarkdownProps extends FlexProps {
  /**
   * Use this when streaming data. For example, getting a result from ChatGPT.
   */
  streaming?: boolean

  /**
   * Markdown data to render as elements.
   */
  data?: string

  normalizer?: MarkdownNormalizer

  disableScrolling?: boolean

  variant?: MarkdownVariant

  /**
   * Allows a line clamp to be set by default, which will be removed when the component is toggled.
   */
  lineClamp?: number
}

const StreamingDot = memo<{ active: boolean }>(({ active }) => {
  // Avoids weird cases where the dot briefly shows
  // Definitely a bit of a hack, but it works
  const [state, setState] = useState({ active, frozen: false })

  useEffect(() => {
    setState((prev) => {
      if (prev.frozen) return prev
      if (active === prev.active) return prev
      if (active) return { ...prev, active }
      return { active, frozen: true }
    })
  }, [setState, active])

  if (!state.active) return null
  return <span className={styles.streamingDot} />
})

/**
 * Renders markdown content. Uses `'react-markdown'` behind the scenes.
 *
 * @param props
 * @returns
 */
const Markdown: React.FC<MarkdownProps> = (props: MarkdownProps) => {
  const {
    className: propsClassName,
    streaming,
    children,
    data = '',
    direction = 'column',
    disableScrolling = false,
    scrollY = !disableScrolling,
    normalizer = DEFAULT_NORMALIZER,
    variant,
    lineClamp,
    ...forwarded
  } = props

  const className = classNames(
    styles.root,
    {
      [styles.variantGuide]: variant === MarkdownVariant.GUIDE,
      [styles.variantAIResponse]: variant === MarkdownVariant.AI_RESPONSE,
      [styles.variantChat]: variant === MarkdownVariant.CHAT,
      [styles.variantFeatureDescription]: variant === MarkdownVariant.FEATURE_DESCRIPTION,
    },
    propsClassName
  )

  /** Whether the text is expanded or not. */
  const expanded = useBooleanHelper({ initialValue: false })

  if (!lineClamp) {
    return (
      <Flex
        className={className}
        p="sm"
        direction={direction}
        scrollY={scrollY}
        {...forwarded}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{normalizer(data)}</ReactMarkdown>
        {children}
        <StreamingDot active={streaming || false} />
      </Flex>
    )
  }

  return (
    <Flex
      className={className}
      p="sm"
      direction={direction}
      scrollY={scrollY}
      {...forwarded}
    >
      <Text
        lineClamp={expanded ? lineClamp : undefined}
        // Not ideal for accessibility, but does allow keyboard navigation.
        // We'll want to improve this in the future.
        // Ideally, this "toggle text" concept will be pulled into a separate component.
        onClick={expanded.toggle}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            event.stopPropagation()
            expanded.toggle()
          }
        }}
        tabIndex={0}
        sx={{ cursor: variant === MarkdownVariant.GUIDE ? 'help' : 'pointer' }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{normalizer(data)}</ReactMarkdown>
      </Text>
      {children}
      <StreamingDot active={streaming || false} />
    </Flex>
  )
}

export default Markdown
