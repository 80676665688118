import { HTMLAttributes } from 'react'
import { classNames } from '@helpers/scls'
import styles from './Flex.module.scss'
import { Flex as MantineFlex, FlexProps as MantineFlexProps } from '@mantine/core'

type FlexSpacing = 'none' | 'sm' | 'md' | 'lg'
type FlexGrow = 'none' | 'sm' | 'md' | 'lg'

export interface FlexProps extends HTMLAttributes<HTMLElement> {
  align?: MantineFlexProps['align']
  direction?: MantineFlexProps['direction']
  gap?: MantineFlexProps['gap']
  justify?: MantineFlexProps['justify']
  wrap?: MantineFlexProps['wrap']
  grow?: FlexGrow

  scrollX?: boolean
  scrollY?: boolean

  sx?: MantineFlexProps['sx']

  p?: FlexSpacing
  px?: FlexSpacing
  py?: FlexSpacing

  m?: FlexSpacing
  mx?: FlexSpacing
  my?: FlexSpacing

  fullHeight?: boolean
  fullWidth?: boolean
}

const Flex = (props: FlexProps) => {
  const {
    className: propsClassName,
    scrollX,
    scrollY,
    grow = 'none',
    fullHeight,
    fullWidth,
    p = 'none',
    px = p,
    py = p,
    m = 'none',
    mx = m,
    my = m,
    ...forwarded
  } = props

  const className = classNames(
    styles.root,
    {
      [styles.scrollX]: scrollX,
      [styles.scrollY]: scrollY,
      [styles.scrollNone]: !scrollX && !scrollY,

      [styles.pyNone]: py === 'none',
      [styles.pySm]: py === 'sm',
      [styles.pyMd]: py === 'md',
      [styles.pyLg]: py === 'lg',
      [styles.pxNone]: px === 'none',
      [styles.pxSm]: px === 'sm',
      [styles.pxMd]: px === 'md',
      [styles.pxLg]: px === 'lg',

      [styles.myNone]: my === 'none',
      [styles.mySm]: my === 'sm',
      [styles.myMd]: my === 'md',
      [styles.myLg]: my === 'lg',
      [styles.mxNone]: mx === 'none',
      [styles.mxSm]: mx === 'sm',
      [styles.mxMd]: mx === 'md',
      [styles.mxLg]: mx === 'lg',

      [styles.growSm]: grow === 'sm',
      [styles.growMd]: grow === 'md',
      [styles.growLg]: grow === 'lg',

      [styles.fullHeight]: fullHeight,
      [styles.fullWidth]: fullWidth,
    },
    propsClassName
  )

  return <MantineFlex className={className} {...forwarded} />
}

export default Flex
