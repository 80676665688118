import { classNames } from '@helpers/scls'
import styles from './Page.module.scss'
import Loader from '@components/loader/Loader'

export interface PageLoaderProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  disableVerticalCentering?: boolean
  message?: string
}

const PageLoader = (props: PageLoaderProps) => {
  const {
    className: propsClassName,
    disableVerticalCentering,
    message,
    ...forwarded
  } = props

  const className = classNames(
    styles.loader,
    {
      [styles.loaderCenterContent]: !disableVerticalCentering,
    },
    propsClassName
  )

  return (
    <div className={className} {...forwarded}>
      <Loader message={message} />
    </div>
  )
}

export default PageLoader
